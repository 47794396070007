export const translations = {
  en: {
    title: "Memory Card Game",
    blog: 'Blog',
    level: "Level {level}",
    timeUsed: "Time Used",
    tool1: "Peek Again",
    tool2: "Auto Match",
    tool3: "Shuffle",
    restart: "Restart",
    matched: "Matched",
    score: "Score",
    combo: "Combo",
    nextLevel: "Next Level",
    congratulations: "Congratulations! Total Score:",
    points: "points",
    matchedCount: "Matched: {count} / {total}",
    scoreLabel: "Score: {score}",
    comboPoints: "+{points} points! {combo} Combo!",
    leaderboard: "Leaderboard",
    speedRanking: "Speed Ranking",
    scoreRanking: "Score Ranking",
    totalPlayers: "Total Players",
    yourName: "Your Name",
    edit: "Edit",
    save: "Save",
    yourBest: "Your Best Records",
    latestArticles: 'Latest Articles',
    latestArticlesDescription: 'Stay updated with our latest tips and strategies',
    viewAllArticles: 'View All Articles',
    howToPlay: "How to Play",
    step1Title: "Observe the Cards",
    step1Description: "Look at the cards carefully and try to remember their positions",
    step2Title: "Find Matches",
    step2Description: "Click on two cards to find matching pairs",
    step3Title: "Complete the Level",
    step3Description: "Match all pairs to advance to the next level",
  },
  zh: {
    title: "记忆卡牌游戏",
    blog: '博客',
    level: "第{level}关",
    timeUsed: "已用时",
    tool1: "再看一次",
    tool2: "随机消除",
    tool3: "刷新位置",
    restart: "重新开始",
    matched: "已匹配",
    score: "得分",
    combo: "连击",
    nextLevel: "下一关",
    congratulations: "恭喜通关！总分：",
    points: "分",
    matchedCount: "已匹配: {count} / {total}",
    scoreLabel: "得分: {score}",
    comboPoints: "+{points} 分！{combo} 连击！",
    leaderboard: "排行榜",
    speedRanking: "速度排名",
    scoreRanking: "得分排名",
    totalPlayers: "总参与人数",
    yourName: "你的名字",
    edit: "编辑",
    save: "保存",
    yourBest: "你的最佳记录",
    latestArticles: '最新文章',
    latestArticlesDescription: '了解最新的技巧和策略',
    viewAllArticles: '查看全部文章',
    howToPlay: "游戏玩法",
    step1Title: "观察卡牌",
    step1Description: "仔细观察卡牌并记住它们的位置",
    step2Title: "寻找配对",
    step2Description: "点击两张卡牌来寻找匹配的对子",
    step3Title: "完成关卡",
    step3Description: "匹配所有卡牌对以进入下一关",
  },
  ja: {
    title: "メモリーカードゲーム",
    blog: 'ブログ',
    level: "レベル{level}",
    timeUsed: "経過時間",
    tool1: "もう一度見る",
    tool2: "自動マッチ",
    tool3: "シャッフル",
    restart: "リスタート",
    matched: "マッチ済み",
    score: "スコア",
    combo: "コンボ",
    nextLevel: "次のレベル",
    congratulations: "おめでとう！合計スコア：",
    points: "点",
    matchedCount: "マッチ: {count} / {total}",
    scoreLabel: "スコア: {score}",
    comboPoints: "+{points}点！{combo}コンボ！",
    leaderboard: "ランキング",
    speedRanking: "スピードランキング",
    scoreRanking: "スコアランキング",
    totalPlayers: "総プレイヤー数",
    yourName: "プレイヤー名",
    edit: "編集",
    save: "保存",
    yourBest: "あなたの最高記録",
    latestArticles: '最新の記事',
    latestArticlesDescription: '最新のヒントと戦略をチェック',
    viewAllArticles: '全ての記事を見る',
    howToPlay: "遊び方",
    step1Title: "カードを観察",
    step1Description: "カードの位置をよく覚えましょう",
    step2Title: "ペアを探す",
    step2Description: "2枚のカードをクリックしてペアを見つけます",
    step3Title: "レベルクリア",
    step3Description: "すべてのペアを見つけて次のレベルへ",
  },
  es: {
    title: "Juego de Memoria",
    blog: 'Blog',
    level: "Nivel {level}",
    timeUsed: "Tiempo usado",
    tool1: "Mirar de nuevo",
    tool2: "Auto emparejar",
    tool3: "Mezclar",
    restart: "Reiniciar",
    matched: "Emparejados",
    score: "Puntuación",
    combo: "Combo",
    nextLevel: "Siguiente nivel",
    congratulations: "¡Felicitaciones! Puntuación total:",
    points: "puntos",
    matchedCount: "Emparejados: {count} / {total}",
    scoreLabel: "Puntuación: {score}",
    comboPoints: "¡+{points} puntos! ¡{combo} combo!",
    leaderboard: "Tabla de clasificación",
    speedRanking: "Ranking de velocidad",
    scoreRanking: "Ranking de puntuación",
    totalPlayers: "Jugadores totales",
    yourName: "Tu nombre",
    edit: "Editar",
    save: "Guardar",
    yourBest: "Tus mejores registros",
    latestArticles: 'Últimos Artículos',
    latestArticlesDescription: 'Mantente actualizado con nuestros últimos consejos y estrategias',
    viewAllArticles: 'Ver Todos los Artículos',
    howToPlay: "Cómo Jugar",
    step1Title: "Observa las Cartas",
    step1Description: "Mira las cartas con atención y recuerda sus posiciones",
    step2Title: "Encuentra Parejas",
    step2Description: "Haz clic en dos cartas para encontrar parejas coincidentes",
    step3Title: "Completa el Nivel",
    step3Description: "Empareja todas las cartas para avanzar al siguiente nivel",
  }
}; 