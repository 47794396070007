import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '../../contexts/ThemeContext';

const BlogPost = () => {
  const { slug } = useParams();
  const { theme } = useTheme();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // 获取文章元数据
        const postsResponse = await fetch('/data/blog-posts.json');
        if (!postsResponse.ok) {
          console.error('Failed to fetch posts:', postsResponse.statusText);
          throw new Error('Failed to fetch posts');
        }
        const posts = await postsResponse.json();
        const postData = posts.find(p => p.slug === slug);
        
        if (!postData) {
          console.error('Post not found:', slug);
          throw new Error('Post not found');
        }

        // 获取文章内容
        const contentResponse = await fetch(`/content/blog/posts/${slug}.html`);
        if (!contentResponse.ok) {
          console.error('Failed to fetch content:', contentResponse.statusText);
          throw new Error('Failed to fetch content');
        }
        
        const content = await contentResponse.text();
        setPost({
          ...postData,
          content
        });
      } catch (error) {
        console.error('Error fetching post:', error);
        setPost(null);
      } finally {
        setLoading(false);
      }
    };

    if (slug) {
      fetchPost();
    }
  }, [slug]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="text-xl">Post not found</div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${
      theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'
    }`}>
      <article className="container mx-auto px-4 py-8 max-w-4xl">
        {post.coverImage && (
          <img 
            src={post.coverImage} 
            alt={post.title}
            className="w-full h-64 object-cover rounded-lg mb-8"
            onError={(e) => e.target.style.display = 'none'}
          />
        )}
        <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
        <div className="text-sm text-gray-500 dark:text-gray-400 mb-8">
          <time>{new Date(post.date).toLocaleDateString()}</time>
          <span className="mx-2">·</span>
          <span>{post.readingTime} min read</span>
        </div>
        <div 
          className={`prose max-w-none ${theme === 'dark' ? 'prose-invert' : ''} prose-lg prose-blue`}
          dangerouslySetInnerHTML={{ __html: post.content }}
        />
      </article>
    </div>
  );
};

export default BlogPost; 