import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { useLanguage } from '../contexts/LanguageContext';

const LatestArticles = () => {
  const { theme } = useTheme();
  const { t } = useLanguage();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('/data/blog-posts.json');
        if (!response.ok) {
          throw new Error('Failed to fetch articles');
        }
        const data = await response.json();
        // 按日期排序并只取最新的3篇
        const sortedArticles = data
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .slice(0, 3);
        setArticles(sortedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return (
      <div className="animate-pulse">
        {[1, 2, 3].map((i) => (
          <div key={i} className="mb-6">
            <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 mb-2"></div>
            <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-1/2"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {articles.map((article) => (
        <Link
          key={article.slug}
          to={`/blog/${article.slug}`}
          className={`block p-6 rounded-lg transition-transform duration-200 hover:scale-105 ${
            theme === 'dark' ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-50'
          } shadow-md`}
        >
          <article>
            <h3 className={`text-xl font-semibold mb-2 ${
              theme === 'dark' ? 'text-white' : 'text-gray-900'
            }`}>
              {article.title}
            </h3>
            <p className={`text-sm mb-3 ${
              theme === 'dark' ? 'text-gray-300' : 'text-gray-600'
            }`}>
              {article.excerpt}
            </p>
            <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
              <time>{new Date(article.date).toLocaleDateString()}</time>
              <span className="mx-2">·</span>
              <span>{article.readingTime} min read</span>
            </div>
          </article>
        </Link>
      ))}
      <div className="text-center mt-8">
        <Link
          to="/blog"
          className={`inline-flex items-center px-6 py-3 rounded-lg font-medium transition-colors ${
            theme === 'dark'
              ? 'bg-blue-600 hover:bg-blue-700 text-white'
              : 'bg-blue-100 hover:bg-blue-200 text-blue-700'
          }`}
        >
          {t('viewAllArticles', 'View All Articles')} →
        </Link>
      </div>
    </div>
  );
};

export default LatestArticles;
